<template>
  <div class="matchmakerList">
    <div class="matchmakerList-t">
      <div class="search-l">
        <div class="img"><img src="./img/search.png" alt="" /></div>
        <div class="input">
          <v-input v-model="form.name" placeholder="输入关键字"></v-input>
        </div>
      </div>
      <div class="search-r" @click="toSearch">搜索</div>
    </div>
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div
        class="list-content"
        v-for="(item, index) in listData"
        :key="index"
        @click="toRouteTitle(item)"
      >
        <div class="item-l">
          <img :src="item.picture" alt="" />
        </div>
        <div class="item-r">
          <div class="num" v-if="item.number">编号：{{ item.number }}</div>
          <div class="peopleName">姓名：{{ item.peopleName }}</div>
          <div class="mobile">联系方式：{{ item.mobile }}</div>
          <div class="status" v-if="item.progressStatus">
            状态：{{ progressStatusMap[item.progressStatus] }}
          </div>
        </div>
      </div>
    </v-list>
    <div class="dialog" v-if="isShowBox">
      <div class="box">
        <div class="img" @click="cancel">
          <img src="./img/close.png" alt="" />
        </div>
        <div class="title">设置进度</div>
        <div class="oSelect" @click="showList">
          {{ oStatus ? oStatus : "请选择" }}
          <div :class="['arrow', { isUp: isshow }]">
            <img src="./img/downArrow.png" alt="" />
          </div>
          <div class="oList" v-if="isshow">
            <div
              class="item"
              v-for="(item, index) in progressStatus"
              :key="index"
              @click="selectStatus(item)"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
        <div class="btn">
          <div class="btn-l" @click="cancel">取消</div>
          <div class="btn-r" @click="modifyStatus">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { progressStatusMap, progressStatus } from "./map.js";
import { getDsfRegisterInfoListUrl, updateStatusUrl } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "matchmakerList",
  data() {
    return {
      oStatus: "",
      status: "",
      id: "",
      isshow: false,
      progressStatus,
      progressStatusMap,
      finishedText: "没有更多了",
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      form: { name: "" },
      listData: [],
      bannerList: {},
      isShowBox: false,
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {},
  methods: {
    toSearch() {
      this.requestData = {
        curPage: 1,
        pageSize: 10,
      };
      this.listData = [];
      this.getPartyList();
    },
    async modifyStatus() {
      if (!this.status) {
        this.$toast("请选择状态");
        return;
      }
      let params = {
        id: this.id,
        progressStatus: this.status,
        userId: this.userId,
      };
      let res = await this.$axios.post(updateStatusUrl, params);
      if (res.code === 200) {
        this.isShowBox = false;
        this.oStatus = "";
        this.status = "";
        this.requestData = {
          curPage: 1,
          pageSize: 10,
        };
        this.listData = [];
        this.getPartyList();
      } else {
      }
    },
    cancel() {
      this.isShowBox = false;
    },
    selectStatus(item) {
      this.oStatus = item.text;
      this.status = item.value;
    },
    showList() {
      this.isshow = !this.isshow;
    },
    toRouteTitle(row) {
      this.isShowBox = true;
      this.id = row.id;
    },
    onLoad() {
      this.getPartyList();
    },
    async getPartyList() {
      let res = await this.$axios.get(getDsfRegisterInfoListUrl, {
        params: {
          tenantId: this.tenantId,
          name: this.form.name,
          auditStatus: 1,
          curPage: this.requestData.curPage++,
          pageSize: this.requestData.pageSize,
        },
      });
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.listData = [];
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData.push(item);
        });
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.matchmakerList {
  min-height: 100vh;
  padding: 18px 40px;
  box-sizing: border-box;
  padding-top: 120px;
  .matchmakerList-t {
    display: flex;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    align-items: center;
    padding: 20px 48px 20px 40px;
    justify-content: space-between;
    .search-l {
      width: 590px;
      height: 68px;
      background: #f5f8fb;
      border-radius: 34px;
      display: flex;
      align-items: center;
      padding: 14px 30px;
      box-sizing: border-box;
      .input {
        flex: 1;
      }
      /deep/ .van-cell {
        padding: 0;
        background: none;
      }
      .img {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .search-r {
      font-weight: 400;
      font-size: 28px;
      color: #fb8133;
      line-height: 40px;
    }
  }
  .list-content {
    height: 276px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 17px;
    padding: 32px 18px;
    box-sizing: border-box;
    display: flex;
    .item-l {
      width: 248px;
      flex-shrink: 0;
      border-radius: 17px;
      margin-right: 18px;
      img {
        width: 100%;
        border-radius: 17px;
        height: 100%;
      }
    }
    .item-r {
      .num {
        font-weight: 700;
        font-size: 32px;
        color: #1a1c34;
        line-height: 44px;
      }
      .peopleName,
      .mobile {
        font-weight: 400;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 36px;
        margin: 20px 0 0 0;
      }
      .status {
        font-weight: 400;
        font-size: 26px;
        color: #fb8337;
        line-height: 36px;
        margin: 18px 0 0 0;
      }
    }
  }
  .dialog {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    .box {
      width: 560px;
      height: 452px;
      background: linear-gradient(180deg, #f8faff 0%, #ffffff 100%);
      border-radius: 16px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding: 40px;
      box-sizing: border-box;
      .title {
        font-weight: 500;
        font-size: 30px;
        color: #333333;
        line-height: 42px;
      }
      .oSelect {
        height: 68px;
        background: #f3f3f3;
        border-radius: 10px;
        padding: 14px 40px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
        margin: 86px 0 76px;
        position: relative;
        .arrow {
          width: 22px;
          height: 14px;
          position: absolute;
          top: 0;
          bottom: 0;
          font-size: 0;
          right: 40px;
          margin: auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .isUp {
          transform: rotate(180deg);
        }
        .oList {
          position: absolute;
          width: 100%;
          background: #f3f3f3;
          bottom: -200px;
          left: 0;
          border-radius: 0 0 10px 10px;
          .item {
            padding: 14px 40px;
          }
        }
      }
      .btn {
        display: flex;
        justify-content: space-between;
        .btn-l,
        .btn-r {
          width: 226px;
          height: 74px;
          background: #fc635e;
          border-radius: 44px;
          font-weight: 600;
          font-size: 30px;
          color: #ffffff;
          line-height: 74px;
          text-align: center;
        }
        .btn-l {
          background: #ffffff;
          border: 2px solid #fc635e;
          color: #fc635e;
        }
      }
      .img {
        width: 22px;
        font-size: 0;
        height: 22px;
        position: absolute;
        top: 50px;
        right: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
