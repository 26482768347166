import { mapHelper } from "@/utils/utils";
//生肖
const animalsType = [
  {
    text: "鼠",
    value: "鼠",
  },
  {
    text: "牛",
    value: "牛",
  },
  {
    text: "虎",
    value: "虎",
  },
  {
    text: "兔",
    value: "兔",
  },
  {
    text: "龙",
    value: "龙",
  },
  {
    text: "蛇",
    value: "蛇",
  },
  {
    text: "马",
    value: "马",
  },
  {
    text: "羊",
    value: "羊",
  },
  {
    text: "猴",
    value: "猴",
  },
  {
    text: "鸡",
    value: "鸡",
  },
  {
    text: "狗",
    value: "狗",
  },
  {
    text: "猪",
    value: "猪",
  },
];

const { map: animalsTypeMap, setOps: animalsTypeOps } =
  mapHelper.setMap(animalsType);
//学历
const educationType = [
  {
    text: "小学",
    value: "小学",
  },
  {
    text: "初中",
    value: "初中",
  },
  {
    text: "高中",
    value: "高中",
  },
  {
    text: "大专",
    value: "大专",
  },
  {
    text: "中专",
    value: "中专",
  },
  {
    text: "本科",
    value: "本科",
  },
  {
    text: "硕士研究生",
    value: "硕士研究生",
  },
  {
    text: "博士研究生",
    value: "博士研究生",
  },
  {
    text: "其他",
    value: "其他",
  },
];

const { map: educationTypeMap, setOps: educationTypeOps } =
  mapHelper.setMap(educationType);
//婚史
const hunType = [
  {
    text: "未婚",
    value: "未婚",
  },
  {
    text: "领证未婚",
    value: "领证未婚",
  },
  {
    text: "短婚未育",
    value: "短婚未育",
  },
  {
    text: "丧偶",
    value: "丧偶",
  },
  {
    text: "离婚无孩",
    value: "离婚无孩",
  },
  {
    text: "离婚有孩",
    value: "离婚有孩",
  },
  {
    text: "抚养费由对方/本人承担",
    value: "抚养费由对方/本人承担",
  },
];
const { map: hunTypeMap, setOps: hunTypeOps } = mapHelper.setMap(hunType);

//学制
const xueZhiType = [
  {
    text: "全日制",
    value: "全日制",
  },
  {
    text: "非全日制",
    value: "非全日制",
  },
];
const { map: xueZhiTypeMap, setOps: xueZhiTypeOps } =
  mapHelper.setMap(xueZhiType);
//产权
const chanQuanType = [
  {
    text: "父母",
    value: "父母",
  },
  {
    text: "本人",
    value: "本人",
  },
  {
    text: "其他",
    value: "其他",
  },
];
const { map: chanQuanTypeMap, setOps: chanQuanTypeOps } =
  mapHelper.setMap(chanQuanType);
//特长
const teChangType = [
  {
    text: "外向性",
    value: "外向性",
  },
  {
    text: "喜游泳",
    value: "喜游泳",
  },
  {
    text: "中性",
    value: "中性",
  },
  {
    text: "喜逛街",
    value: "喜逛街",
  },
  {
    text: "内向",
    value: "内向",
  },
  {
    text: "喜理财",
    value: "喜理财",
  },
  {
    text: "喜交友",
    value: "喜交友",
  },
  {
    text: "喜唱歌",
    value: "喜唱歌",
  },
  {
    text: "喜看戏",
    value: "喜看戏",
  },
  {
    text: "打麻将",
    value: "打麻将",
  },
  {
    text: "喜股票",
    value: "喜股票",
  },
  {
    text: "喜安静",
    value: "喜安静",
  },
  {
    text: "喜运动",
    value: "喜运动",
  },
  {
    text: "喜上网",
    value: "喜上网",
  },
  {
    text: "喜宠物",
    value: "喜宠物",
  },
  {
    text: "喜看书",
    value: "喜看书",
  },
  {
    text: "喜事业",
    value: "喜事业",
  },
];
const { map: teChangTypeMap, setOps: teChangTypeOps } =
  mapHelper.setMap(teChangType);
//大小
const bigSmallType = [
  {
    text: "大",
    value: "大",
  },
  {
    text: "小",
    value: "小",
  },
];
const { map: bigSmallTypeMap, setOps: bigSmallTypeOps } =
  mapHelper.setMap(bigSmallType);
//择偶区域
const areaType = [
  {
    text: "宁波市内",
    value: "宁波市内",
  },
  {
    text: "浙江省内",
    value: "浙江省内",
  },
  {
    text: "不限",
    value: "不限",
  },
];
const { map: areaTypeMap, setOps: areaTypeOps } = mapHelper.setMap(areaType);

//要求
const askType = [
  {
    text: "父母双全",
    value: "父母双全",
  },
  {
    text: "接受单亲",
    value: "接受单亲",
  },
  {
    text: "父母有退休金",
    value: "父母有退休金",
  },
  {
    text: "婚史不限",
    value: "婚史不限",
  },
  {
    text: "最好有房",
    value: "最好有房",
  },
  {
    text: "共同买房",
    value: "共同买房",
  },
  {
    text: "已首付共同按揭",
    value: "已首付共同按揭",
  },
  {
    text: "人品好，房可赞助",
    value: "人品好，房可赞助",
  },
];
const { map: askTypeMap, setOps: askTypeOps } = mapHelper.setMap(askType);
//进度状态
const progressStatus = [
  {
    text: "牵线中",
    value: "1",
  },
  {
    text: "配对中",
    value: "2",
  },
  {
    text: "配对完成",
    value: "3",
  },
];
const { map: progressStatusMap, setOps: progressStatusOps } =
  mapHelper.setMap(progressStatus);

export {
  progressStatus,
  progressStatusMap,
  askType,
  animalsType,
  teChangType,
  educationType,
  chanQuanType,
  hunType,
  xueZhiType,
  bigSmallType,
  areaType,
};
