let origin = window.ORIGIN;
// 提交蝶双飞登记内容
const saveOrUpdateDsfRegisterInfoUrl = `${origin}/gateway/hc-serve/dsf/saveOrUpdateDsfRegisterInfo`;
//牵线进度-审核
const updateStatusUrl = `${origin}/gateway/hc-serve/dsf/updateStatus`;
//查询蝶双飞信息列表
const getDsfRegisterInfoListUrl = `${origin}/gateway/hc-serve/dsf/getDsfRegisterInfoList`;
//保存或更新蝶双飞详情内容
const saveOrUpdateDsfContentInfoUrl = `${origin}/gateway/hc-serve/dsf/saveOrUpdateDsfContentInfo`;
//撤回提交
const withdrawalUrl = `${origin}/gateway/hc-serve/dsf/withdrawal`;
//根据用户ID获取蝶双飞登记信息
const getDsfRegisterInfoByUserIdUrl = `${origin}/gateway/hc-serve/dsf/getDsfRegisterInfoByUserId`;
//查询蝶双飞信息列表
const getDsfContentInfoListUrl = `${origin}/gateway/hc-serve/dsf/getDsfContentInfoList`;
//获取权限
const getFunctionSettingUrl = `${origin}/gateway/blade-system/application/getFunctionSetting`;
//用户信息
const userInfoUrl = `${origin}/gateway/hc-mini/user/mini/user-info`;

export {
  userInfoUrl,
  getFunctionSettingUrl,
  saveOrUpdateDsfRegisterInfoUrl,
  updateStatusUrl,
  getDsfRegisterInfoListUrl,
  saveOrUpdateDsfContentInfoUrl,
  withdrawalUrl,
  getDsfRegisterInfoByUserIdUrl,
  getDsfContentInfoListUrl,
};
